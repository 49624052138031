/**
 * Main application Sass file
 */
 @import '~@bryntum/demo-resources/scss/example.scss';
 .App {
    width: 100%;
    height: 100%;
    p {
        margin: 0;
    }
 }

 .b-sch-event {
    border-radius : 3px;
    font-weight   : 300;
    box-shadow    : 0 1px 1px 0 rgba(0, 0, 0, .25);

    &.lubrication {
        background: repeating-linear-gradient(-45deg, #01BAEF, #01BAEF 7px, white 7px, white 14px);
        border-color: #01BAEF;
    }
}

.b-sch-line-start,
.b-sch-line {
    border-color     : #f33;

    &.special {
        border-color     : #000;
    }
}
.b-sch-event-wrap.b-milestone-wrap {
    .b-sch-event.b-sch-event-withicon .b-sch-event-content {
        border-radius : 100%;
        font-size     : 50%;
        width         : 2em;
        height        : 2em;
        box-shadow    : 0 1px 1px 0 rgba(0, 0, 0, .25);

        .b-fa, .b-icon {
            color : inherit;;
        }
    }

    label {
        color : #777;
    }
}

.b-sch-dependency {
    &.special-dependency {
        stroke-dasharray : 5, 5;
        stroke-width     : 2;
    }

    &.extra-special-dependency {
        stroke-dasharray : 6;
    }
}

@keyframes draw {
    50% {
        stroke-dashoffset : 0;
    }
}


#main {
  display: flex;
  flex-direction: row;
//   height: 100%;

  .b-react-scheduler-container {
      flex: 1 1 0;
      overflow: hidden;
  }
}
.unplanned-grid {
.b-gridbase {
  flex: none;
  min-width: 220px;

  .b-grid-cell {
      cursor: pointer;
  }

  i {
      margin-inline-end: 10px;
      color: #4887e5;
  }
}
}

.b-grid-header {
  height: 80px;
}

.b-unassigned-class.b-drag-proxy {
  background: green;
  color: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  height: 42px !important;

  &.b-drag-invalid {
      background: red;
  }

  &.b-horizontal {
      align-items: center;
      padding-inline-start: 0.5em;
      flex-flow: row nowrap;
      justify-content: flex-start;

      i {
          margin-inline-end: 0.5em;
      }
  }

  &.b-vertical {
      justify-content: flex-start;
      padding-top: 0.5em;
  }
}

.b-aborting.b-unassigned-class {
  transition: transform 0.3s !important;

  background: red;
  position: absolute !important;
  z-index: 10000;
  pointer-events: none;
  opacity: 0.8;
  box-sizing: border-box;
}
